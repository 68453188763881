@import 'normalize.css';
@import 'antd.default.css';
@import '~antd/lib/radio/style';
@import '~antd/lib/checkbox/style';
@import '~antd/lib/typography/style';
@import '~antd/lib/button/style';
@import '~antd/lib/result/style';
@import '~antd/lib/upload/style';
@import '~antd/lib/table/style';
@import '~antd/lib/date-picker/style';
@import '~antd/lib/select/style';
@import '~antd/lib/input/style';
@import '~antd/lib/input-number/style';
@import '~antd/lib/auto-complete/style';
@import '~antd/lib/modal/style';
@import '~antd/lib/message/style';
@import '~antd/lib/icon/style';
@import '~antd/lib/dropdown/style';
@import '~antd/lib/list/style';
@import '~antd/lib/form/style';
@import '~antd/lib/popover/style';
@import '~antd/lib/popconfirm/style';

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

.container-main > main {
    width: calc(100% - 350px) !important;
    @media screen and (max-width: 1024px) {
        width: 100% !important;
    }
}

body {
    font-family: "IBMPlexSans","ChevinPro","Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;
}
#webbot {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
    font-weight: 400;
    background: #fff;
    padding: 15px;
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
    p {
        margin: 0;
    }
    svg {
        vertical-align: unset;
    }
}

h4.ant-typography,
.ant-typography h4 {
    @media screen and (max-width: 1024px) {
        font-size: 18px;
    }
}
.ant-typography {
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

#webbot {
    .app {
        width: 100%;
        margin: 0 auto;
    }
    .questionnaire {
        &__radio-group {
            margin-top: -10px;
            & .ant-radio-button-wrapper {
                margin-right: 10px;
                margin-top: 10px;
                border-left-width: 1px;
            }
        }
        &-form {
            margin-top: 20px;
            border-top: 1px solid #d9d9d9;
            &__field {
                margin-top: 25px;
                &:last-child {
                    @media screen and (max-width: 479px) {
                        display: flex;
                        flex-direction: column;
                        align-content: flex-start;
                        justify-content: flex-start;
                    }
                }
                @media screen and (max-width: 1024px) {
                    margin-top: 20px;
                }
            }
            &__dates {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                & .ant-form-item {
                    margin-bottom: 0;
                }
            }
            &__date {
                display: flex;
                justify-content: center;
                align-items: center;
                @media screen and (max-width: 380px) {
                    flex-basis: 140px;
                }
                & + div {
                    margin-left: 20px;
                    @media screen and (max-width: 479px) {
                        margin-left: 10px;
                    }
                }
                &-title {
                    margin-right: 10px;
                }
                & .ant-form-item-explain {
                    display: none !important;
                }
            }
            &__till-now {
                margin-left: 20px;
                @media screen and (max-width: 479px) {
                    flex-basis: 100%;
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
            & .ant-radio-wrapper {
                white-space: pre-wrap;
            }
            &__field {
                & .ant-form-item {
                    margin-bottom: 0;
                }
            }
            &__cancel {
                @media screen and (max-width: 479px) {
                    margin-left: 0 !important;
                    margin-top: 10px;
                }
            }
        }
        &-education,
        &-career,
        &-hobby,
        &-achievements,
        &-remarkable-events {
            &-form {
                &__table {
                    margin-top: 10px;
                    @media screen and (max-width: 700px) {
                        & tr {
                            display: block;
                            border-bottom: 1px solid #f0f0f0;
                        }
                        & tr {
                            margin-bottom: 5px;
                        }
                        & th,
                        & td {
                            display: block;
                            text-align: left;
                            border: 0 !important;
                            padding: 5px 0 !important;
                        }
                    }
                }
                &__radio {
                    display: block;
                    min-height: 30px;
                    padding-left: 25px;
                    &-group {
                    }
                    & .ant-radio {
                        position: absolute;
                        top: 9px;
                        left: 0;
                    }
                    & span.ant-radio + * {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
                &__buttons {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    margin-top: 5px;
                    & button {
                        margin-right: 10px;
                        margin-top: 10px;
                    }
                }
            }
        }
        &__add {
            display: table;
            margin-top: 25px;
        }
    }

    .link {
        color: #1890ff;
        cursor: pointer;
    }
    .ant-select {
        max-width: 100%;
    }
    .spin {
        text-align: center;
        border-radius: 4px;
        padding: 30px 50px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
    }
    .toggleDescription {
        display: table;
        cursor: pointer;
        color: #1890ff;
        margin-bottom: 20px;
        margin-top: -10px;
    }
    .preview {
        position: relative;
        padding: 0 40px;
        display: block;
        overflow: hidden;
        margin: 0;
        &-overlay {
            overflow: hidden;
            &__content {
                transition: .05s;
            }
        }
        &__arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 0;
            width: 30px;
            height: 30px;
            line-height: 30px;
            margin-top: -15px;
            overflow: hidden;
            border-radius: 100%;
            cursor: pointer;
            background-color: #c5e3ff;
            outline: none;
            &_prev {}
            &_next {
                right: 0;
                left: initial;
            }
        }
    }
    .questionnaire-upload {
        margin-top: 30px;
        display: inline-grid;
    }
    .ant-result {
        margin: 0 auto;
    }
    .ant-result-title {
        line-height: 1.4;
        @media screen and (max-width: 479px) {
            font-size: 18px;
            line-height: 1.4;
        }
    }
    .preview__picture svg {
        & #ranges > *,
        & #pointers > *,
        & #labels > *,
        & #sublabels > * {
            transition: .3s;
            cursor: pointer;
            &:hover {
                opacity: .8;
            }
        }
    }
}
.view {
    margin-top: 30px;
    &-item {
        padding: 10px 0;
        border-top: 1px solid #d9d9d9;
        position: relative;
        &_one {
            border-top: 0 !important;
            border-bottom: 0 !important;
        }
        &__actions {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            position: absolute;
            top: 10px;
            right: 0;
            cursor: pointer;
            &-remove {
                margin-left: 10px;
            }
        }
        &:last-child {
            border-bottom: 1px solid #d9d9d9;
        }
        & .ant-typography {
            margin-bottom: 3px;
        }
        & *:last-child {
            margin-bottom: 0 !important;
        }
        & table {
            & tr:last-child {
                & td {
                    border-bottom: 0 !important;
                }
            }
            & td {
                padding: 5px !important;
                @media screen and (max-width: 479px) {
                    font-size: 12px;
                }
            }
        }
        &__title {
            padding-bottom: 7px;
            font-size: 16px !important;
            padding-right: 40px;
            &-edit {
                margin-left: 10px;
                color: #1890ff;
                font-size: 16px;
            }
            &-input {
                font-size: 14px;
            }
            &-buttons {
                margin: 0;
            }
        }
        &__table {
            margin-top: 10px;
        }
        @media screen and (max-width: 479px) {
            font-size: 12px;
        }
    }
}

.activity-container {
    display: block !important;
}
